import React from 'react'
import { Link } from 'gatsby'

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <p>&copy; cognitio 2018. All Rights Reserved.</p>
      <ul className="list-inline">
        <li className="list-inline-item">
          <a href="#">Impressum</a>
        </li>
        <li className="list-inline-item">
          <a href="#">Datenschutz</a>
        </li>

      </ul>
    </div>
  </footer>
)

export default Footer;