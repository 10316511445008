import React from 'react'
import PropTypes from 'prop-types'
import Helmet, {HelmetProps} from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'


import Navbar from './navbar'
import Footer from './footer'
import './layout.css'





class Layout extends React.Component {

  constructor() {
    super();
    this.state = {
      shrinkNavigation: false,
    }

    const helmet = Helmet.peek()
    console.log(helmet)
  }

  componentDidMount() {
    console.log(HelmetProps)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (e) => {
    if(window.scrollY > 54 && !this.setState.shrinkNavigation) {
      this.setState({
        shrinkNavigation: true
      })
    } else {
      this.setState({
        shrinkNavigation: false
      })
    }
  }

  render() {
    console.log(this.helmet)
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              link={[
                {href:'vendor/fontawesome-free/css/all.min.css', rel:'stylesheet'}
              ]}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
              ]}

              ref={(helmet) => this.helmet = helmet}
            >
              <html lang="de" />
              <link href="" rel="stylesheet"/>
              <link href="" rel="stylesheet"/>
              <link href="" rel="stylesheet"/>
              <link rel="stylesheet" href="static/device-mockups/device-mockups.min.css"/>
              <link rel="favicon" href="/favicon.ico" />
            </Helmet>
            <Navbar shrinked={this.state.shrinkNavigation}/>

            {this.props.children}

            <Footer/>
          </>
        )}
      />
    )
  }
}



Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
