import React from 'react'
import { Link } from 'gatsby'

const Navbar = ({ siteTitle, shrinked }) => (
  <nav className={`navbar navbar-expand-lg navbar-light fixed-top ${shrinked && 'navbar-shrink'}`} id="mainNav">
    <div className="container">
      <a className="navbar-brand js-scroll-trigger" href="#page-top">
        <img src="/img/logo.svg" width="50" alt="Klosterpfädchen Ober-Werbe" />
      </a>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive"
        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i className="fas fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to="/"  activeClassName="active" className="nav-link js-scroll-trigger">Start</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link js-scroll-trigger" to="/impressum">Impressum</Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link js-scroll-trigger" to="/datenschutz">Datenschutz</Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default Navbar;